<template>
<div class="main">
  <nav>
  <!-- <div class="logo">
    <div class="arrow-down"></div>
  </div> -->
  <!-- <div>
  <a href="tel:5519971594580"><img class="icon32" src='@/assets/phone-16.svg'/> (19) 9 7159-4580</a>
  <a href="tel:5519971594580"><img class="icon32" src='@/assets/phone-16.svg'/> (19) 9 7159-4580</a>
  </div> -->
  <!-- <ul>
    <li><a href="tel:5519971594580"><img class="icon32" src='@/assets/phone-32.svg'/> (19) 9 7159-4580</a></li>
    <li><a href="tel:5519971594580"><img class="icon32" src='@/assets/phone-32.svg'/> (19) 9 7159-4580</a></li>
    <li>FAQs</li>
    <li>Contact</li> 
  </ul> -->
</nav>
<section class="banner art">
  <div class="container">
    <div class="banner-text">
      <h1>Binho Guincho Campinas</h1>
      <p><strong>A melhor opção em guincho em campinas é na Binho Guincho Campinas. </strong>Atuamos com dedicação e ética fornecendo o melhor serviço em auto socorro automotivo, reboque de veículos de todos os tipos e equipamentos em geral. Atuamos 24 horas por dia, atendemos em qualquer horário e localização que você se encontre na Região.</p>
      <a href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento."><button class="b1"><img class="icon32" src="@/assets/whatsapp-32.svg"> WhatsApp 24 Horas</button></a>
      <a href="tel:5519971594580"><button class="b2"><img class="icon32" src='@/assets/phone-32.svg'/> Ligar agora</button></a>
    </div>
  </div>
  <img class="banner-image" src="@/assets/logo.svg" alt="monitoring" >
</section>

<div class="container art">
  <div class="row">
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/empilhadeira.jpg" alt="monitoring" >
      <h3 class="card-title">Guincho para Empilhadeira</h3>
      <p class="card-text">Guincho para Empilhadeira, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamneto</a>
      </div>
    </div>
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/vans.jpg" alt="monitoring" >
      <h3 class="card-title">Guincho para Vans</h3>
      <p class="card-text">Guincho para vans, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamneto</a>
      </div>
    </div>
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/equipamentos.jpg" alt="team management" >
      <h3 class="card-title">Guincho para Equipamentos</h3>
      <p class="card-text">Guincho para Equipamentos, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamento</a>
      </div>
    </div>
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/barcos.jpg" alt="monitoring" >
      <h3 class="card-title">Guincho para Embarcações</h3>
      <p class="card-text">Guincho para Embarcações, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamento</a>
      </div>
    </div>
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/autos.jpg" alt="snapshots" >
      <h3 class="card-title">Guincho para Automoveis</h3>
      <p class="card-text">Guincho para Automoveis, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamento</a>
      </div>
    </div>
    <div class="mb-30 col-md-6 col-lg-4">
      <div class="card">
      <img class="card-icon" src="@/assets/caminhao.jpg" alt="monitoring" >
      <h3 class="card-title">Guincho para Caminhões</h3>
      <p class="card-text">Guincho para Caminhões, Aceitamos Cartões de crédito e atendemos empresas, remoções em guincho plataforma de forma rápida e preço justo. Atendemos toda cidade de Campinas e Região, São Paulo Capital, litoral e interior.</p>
      <a class="card-link" href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento.">Solicite Orçamento</a>
      </div>
    </div>
  </div>
</div>
<a href="https://api.whatsapp.com/send?phone=5519971594580&amp;text=Olá%20Binho%20Guincho%20Campinas,%20Gostaria%20de%20solicitar%20um%20orçamento." style="position:fixed;width:60px;height:60px;bottom:40px;right:40px;background-color:#25d366;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;
  z-index:1000;" target="_blank"><span><img style="margin-top:10px" src="@/assets/whatsapp-32.svg"></span></a>
<div class="art"><div class="background-light">
  
        <div class="container py-8">
            <h6 class="mt-0 display-4 text-align-center"> <strong class="texto-cor02">Binho Guincho Campinas</strong> é muito mais vantagem!</h6>
            <div class="box">
                <div>
                    <div class="i-flx">
                      <img class="img-responsive" src="@/assets/btn01.svg">
                      <h5 class="text-transform-uppercase">Chegamos no local em até 30 minutos</h5>
                    </div>
                    <div>
                      <p>Sabemos da sua necessidade no atendimento e tratamos nossos clientes com a maior seriedade e transparência chegando ao local em até 30 minutos!</p>
                    </div>
                </div>
                <div class="mgl">
                    <div class="i-flx">
                      <img class="img-responsive" src="@/assets/btn02.svg">
                      <h5 class="text-transform-uppercase">Atendimento com Serviço 24 Horas</h5></div>
                    <div>
                      <p>Assistência inclusive aos finais de semana e feriados. Profissionais adequados e disponíveis próximos do local de atendimento.</p>
                    </div>
                </div>
                <div class="mgl">
                    <div class="i-flx">
                      <img class="img-responsive" src="@/assets/btn03.svg">
                    <h5 class="text-transform-uppercase mgl"> Aceitamos Cartões de débito e crédito</h5></div>
                    <div>
                      <p>Aceitamos as principais bandeiras de cartão de crédito e débito (Visa, Master, Hipercard, American Express, Diners Club International e Aura).</p>
                    </div>
                </div>
            </div>
        </div>
</div>
</div>
<footer class="footer-distributed art">
    <div class="footer-left">
      <h3><span>Binho</span> Guincho Campinas</h3>
      <p class="footer-links">
        <a @click="pg(0)"  class="link-1">Home&nbsp;</a>
        <a @click="pg(1)">Serviços&nbsp;</a>
        <a @click="pg(2)">Vantagem</a>
      </p>
      <p class="footer-company-name">&copy;&nbsp;{{year}},&nbsp;Todos&nbsp;os&nbsp;direitos&nbsp;reservados</p>
    </div>
    <div class="footer-center">
      <div style="margin-bottom:20px"> 
        <!--  <img src="@/assets/local.svg" width="24" height="24">
        <p>{{city}} / {{state}}</p> -->
      </div>
      <div>
        <a style="margin-bottom:20px" href="tel:5519971594580"><span style="color:#92999f;text-decoration:none;font-size:18px"><img src="@/assets/phone-32-red.svg" width="24" height="24">&nbsp;(19)&nbsp;9&nbsp;7159-4580</span></a>
        <!-- <p style="font-size:24px; vertical-align: middle;">&nbsp;(19)&nbsp;9&nbsp;8883-0179</p> -->
      </div>
      <div>
        <p><a style="margin-bottom:20px;display:inline-flex;" class="" href="mailto:contato@binhoguinchocampinas.com.br"><span style="color:#92999f;font-size:18px"><img src="@/assets/envelope-32.svg" width="18" height="18">&nbsp;contato@binhoguinchocampinas.com.br</span></a></p>
      </div>
    </div>
    <div class="footer-right">
      <p class="footer-company-about">
        <span>Sobre a empresa</span>
        O Binho Guincho executa os melhores serviços de guincho somos expecializado nas remoções emergenciais , sejá para veiculos leves, Vans Motos, Embarcações e Equipamentos em geral em Campinas e região
        Em caso de problemas mecânicos, pane elétrica, acidentes ou  estamos a disposição para melhor atende-lo.
      </p>
      <div class="footer-icons">
        <span>Siga-nos</span>
      </div>
      <div class="footer-icons">
        <a href="https://www.instagram.com/binhoguincho/"><span><img src="@/assets/instagram-logo-32.svg" width="32" height="32"></span></a>&nbsp;
        <a href="https://www.facebook.com/binhoguincho/"><span><img src="@/assets/facebook-logo-32.svg" width="32" height="32"></span></a>
      </div>
    </div>
  </footer>
  </div>
</template>

<script>
export default {
  name:'main',
  data:function () {
    return {
      year:'',
      city:null,
      state:null,
      lat:null,
      log:null,
      tt:[]
    }
  },
  created () {
    this.year = new Date().getFullYear()
  },
  methods: {
    pg (x) {
      if (x==5) {
        document.querySelector('.chat').classList.add('hide')
        if (document.querySelector('.chat').requestFullscreen) {
          document.querySelector('.chat').requestFullscreen();
        } else if (document.querySelector('.chat').webkitRequestFullscreen) { /* Safari */
          document.querySelector('.chat').webkitRequestFullscreen();
        } else if (document.querySelector('.chat').msRequestFullscreen) { /* IE11 */
          document.querySelector('.chat').msRequestFullscreen();
        }
      }
      else {
      document.querySelector('.main').scrollTo(0,document.querySelectorAll('.art')[x].offsetTop-100)
      return
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@font-face {
  font-family:"Poppins";
  src:local('../assets/fonts/roboto-v29-latin-regular.eot');
  src:local('../assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
       local('../assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'),
       local('../assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
       local('../assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'),
       local('../assets/fonts/roboto-v29-latin-regular.svg#Poppins') format('svg');
  font-display:swap
}
@font-face {
  font-family:"Roboto";
  src:local('../assets/fonts/roboto-v29-latin-regular.eot');
  src:local('../assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
       local('../assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'),
       local('../assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
       local('../assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'),
       local('../assets/fonts/roboto-v29-latin-regular.svg#Poppins') format('svg');
  font-display:swap
}
// variables
$a:absolute;
// $f:fixed;
$fx:flex;
$n:none;
$bk:block;
$r:relative;
$l:left;
$cl:column;
$c1:#333333;
$c2:#fff;
$c3:#52c158;
$c10: #28a745;
$c4:#0c0c0c;
$c5:#717171;
$c6:#ff0000;
$c7:#f8f8f8;
$c8:#3d3d3c;
$c9:#001f2f;
$p:pointer;
$p_100:100%;
$m1:0.625rem;
$m2:4.375rem;
$m3:3.125rem;
$m4:2.188rem;
$m5:1rem;
/* $c3:#86BB71;
$c1:#94C2ED;
$c6:#E38968;
$c5:#92959E; */
$darkPurple: #f40b0b;
$lightBlue: #ccc;
$activeBlue: #f40b0b;
$white: #ffffff;
$lightPurple: #333333;

// mixins
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}
*{
  text-decoration:none!important;
}
body {
  background: $lightBlue;
  font-family: Lato, sans-serif;
}
.main{
    top:25vh;
    min-width:100vw;
    max-height:100vh;
    flex-direction:column;
    padding:0;
    overflow:scroll;
    overflow-x:hidden
}

// navigation styling
nav {
  height: 50px;
  background: $white;
  @include box-shadow(0, .5rem, 1.5rem, rgba(0,0,0,.15));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  z-index: -2;
  position: relative;
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    li {
      margin-right: 30px;
      color: $activeBlue;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  div{
    right: 0;
  }
  div a{
    background: #0099cc;
    cursor: pointer;
    margin-left: 4px;
  }
  .logo {
    height: 50px;
    width: 75px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    margin-right: 30px;
    background-color: #4b71ff;
    cursor: pointer;
  }
  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #fff;
  }
}
.texto-cor02{
  color:#f40b0b
}
.mgl{
  margin-left: 6px;
}
.box{
  margin: 100px 0;
 display: inline-flex;
}
// banner styles
.banner {
  height: auto;
  margin: 32px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    height: 450px;
    margin-bottom: 32px;
  }
  .banner-image {
    position: absolute;
    opacity: .25;
    right: 0px;
    top: 0px;
    height: 300px;
    z-index: -1;
    /* -webkit-filter: drop-shadow(0 3rem 0.05rem $darkPurple);
  filter: drop-shadow(0 3rem 0.05rem $darkPurple); */
      @media (min-width: 768px) {
        display: inherit; 
        height: 400px;
        top: 0px;
        opacity: 0.5;
      }
    @media (min-width: 992px) {
        height: 500px;
        top: -50px;
      opacity: 1;
      }
    }

  .banner-text {
    max-width: 550px;
    float: left;
    h1 {
      color: $lightPurple;
      font-size: 3rem;
      font-weight: 700;
      letter-spacing: 3px;
      margin-bottom: 1rem;
    }
    p {
      color: $lightPurple;
      font-size: 1.05rem;
      line-height: 1.75;
    }
    strong{
     color: $darkPurple;
    }
  }
  button.b1{
    background: #28a745;
  }
  button.b2{
    background: #0099cc;
  }
  button{
  margin: 4px;
  border: 0;
  border-radius: 50px;
  padding: .75rem 2.75rem;
  color: $white;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,.25);
  }
}
}
.art{
  margin-top: 50px;
  max-width: 100vw;
  padding: 2vw;
}
.art:nth-child(3){
  background: #f8f8f8;
}
// card styles
.mb-30 {
  margin-bottom: 30px;
}
.card {
  // padding: 16px 24px;
  background: $white;
  height: 100%;
  position: relative;
  border: none;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.15);
  border: 2px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  h3.card-title {
    padding:0 10px;
    font-weight: 700;
    font-size: 1.3rem;
    color: $darkPurple;
  }
  p {
    padding:0 10px;
    color: $lightPurple;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 72px;
  }
  .card-link {
    padding:0 10px;
    position: absolute;
    bottom: 18px;
  }
  &:hover {
    -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,.15);
    cursor: pointer;
  }
  .card-icon {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    top: 0;
    // left: -12px;
  }
}
// btn whats
.btn-whats{
    background-color:$c3;
    color:white;
    width:1.875rem;
    height:1.875rem;
    text-decoration:none;
    border-radius:50rem;
    padding:1.25rem;
    bottom:$m1;
    right:$m5;
    position:fixed;
    transition:all .6s;
    animation:pulse 2s infinite;
    z-index:1;
}
.btn-whats:hover{
  background:$c8;
  box-shadow:0 0 12.5rem rgba(0,0,0,.2);
}
.btn-whats > img{
  position:relative;
  font-size:2.4rem;
  margin:-0.313rem -0.063rem;
}
.i-flx{
  display: inline-flex;
  align-items: center;
}
.i-flx:nth-child(1){
  align-items: center;
}
/* footer*/
.footer-distributed{
  position: $fx;
  left: -1vw;
  bottom: -2vw;
	background:$c1;
	box-sizing:border-box;
	min-width:100vw;
	text-align:left;
	font:1rem sans-serif;
	padding:5vh 2.5vw;
}

.banner{
  padding:$m1
}
.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display:inline-block;
	vertical-align:top
}
.footer-distributed .footer-right p em{
	color:$c5
}
.footer-distributed .footer-right p em span{
  text-transform:uppercase;
	color:$c6
}
/* Footer left */
.footer-distributed .footer-left{
	width:40%
}
@keyframes animate{
    0%{
        transform:rotateX(-30deg) rotateY(360deg)
    }
    100%{
        transform:rotateX(-30deg) rotateY(0deg)
    }
}
/* The company logo */
.footer-distributed h3{
	color:#92999f;
	font:normal 2rem 'Open Sans',Roboto;
	margin:0
}
.footer-distributed h3 span{
	color:$c6
}
/* Footer links */
.footer-distributed .footer-links{
	color:$c2;
	margin:1.25rem 0 0.75rem;
	padding:0
}
.footer-distributed .footer-links a{
  color:$c6;
	display:inline-block;
	line-height:1.8;
  font-weight:400;
	text-decoration:none
}
.footer-distributed .footer-company-name{
	color:#92999f;
	font-size:1rem;
	font-weight:normal;
	margin:0
}
/* Footer Center */
.footer-distributed .footer-center{
	width:35%
}

.footer-distributed .footer-center i{
	background-color:$c6;
  color:$c6;
	color:$c2;
	font-size:1.563rem;
	width:2rem;
	height:2rem;
	border-radius:50%;
	text-align:center;
	line-height:2.625rem;
	margin:0.625rem 0.938rem;
	vertical-align:middle
}
.footer-distributed .footer-center i.fa-envelope{
	font-size:1.063rem;
	line-height:2rem
}
.footer-distributed .footer-center p{
	display:inline-block;
	color:$c2;
  font-weight:400;
	vertical-align:middle;
	margin:0
}
.footer-distributed .footer-center p em{
	color:$c5
}
.footer-distributed .footer-center p span{
	display:block;
	font-weight:normal;
	font-size:0.8755rem;
	line-height:2
}
.footer-distributed .footer-center p a{
	color:$c6;
	text-decoration:none
}
.footer-distributed .footer-links a:before {
  content:"|";
  font-weight:300;
  font-size:1.25rem;
  left:0;
  color:$c2;
  display:inline-block;
  padding-right:0.313rem
}
.footer-distributed .footer-links .link-1:before {
  content:none
}
/* Footer Right */
.footer-distributed .footer-right{
	width:20%
}
.footer-distributed .footer-company-about{
	line-height:1.25rem;
	color:#92999f;
	font-size:0.875rem;
	font-weight:normal;
	margin:0
}
.footer-distributed .footer-company-about span{
	display:block;
	color:$c2;
	font-size:0.8755rem;
	margin-bottom:1.25rem
}
.footer-distributed .footer-icons{
	margin-top:1.563rem
}
.footer-distributed .footer-icons span{
  text-transform:uppercase;
  color:$c6
}
.footer-distributed .footer-icons a{
	display:inline-block;
  color:$c6;
	border-radius:0.125rem;
	// font-size:1.25rem;
	// text-align:center;
	// line-height:2rem;
	margin-right:0.25rem;
	margin-bottom:0.313rem
}
.footer-distributed .footer-icons a img{
  color:$c6
}
/* If you don't want the footer to be responsive,remove these media queries */
@media (max-width:880px) {
  .box{
    display: block
  }
  .bx2{
    max-width: 100%
  }
  .box div{
    margin-bottom: 3rem
  }
	.footer-distributed{
		font:0.8755rem sans-serif
	}

  .footer-distributed.art{
    padding:5vh 4px;
  }
	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display:block;
		width:100%;
		margin-bottom:2.5rem;
		text-align:center
	}
  .footer-center div:nth-child(1){
  
  display:inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}
.footer-center div:nth-child(2){
  
  display:inline-flex;
  justify-content: center;
  align-items: center
  
}
.footer-distributed .footer-center i{
	margin-left:0
}
.footer-company-about{
 padding: 2vw;
}
}
</style>
